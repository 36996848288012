@import '@taiga-ui/core/styles/mixins/wrapper.scss';

[tuiWrapper][data-appearance='pool-textfield'] {
    background: var(--tui-secondary);
    border: none !important;
    border-radius: var(--tui-radius-m);
    color: var(--tui-primary-text);
    caret-color: var(--tui-primary-text);
    text-transform: none !important;
    letter-spacing: normal !important;

    @include wrapper-active {
        background: var(--tui-secondary);
        border: none !important;

        &:after {
            display: none;
            border: none !important;
        }
    }

    @include wrapper-hover {
        background: var(--tui-secondary-hover);
        border: none !important;

        &:after {
            display: none;
            border: none !important;
        }
    }

    @include wrapper-focus {
        background: var(--tui-secondary);
        border: none !important;

        &:after {
            display: none;
            border: none !important;
        }
    }

    @include wrapper-invalid {
        background: rgba(41, 42, 46, 1);
        outline: 1px solid var(--tui-error-fill) !important;
    }
}

