@use "styles/mixins/pool-button" as m;

[data-appearance='pool-button'],
[data-appearance='pool-textfield'] {
    width: 100%;
}

[tuiWrapper][data-appearance='pool-button'] {
    @include m.pool-button('pool-button');
    text-transform: lowercase;
}

[tuiWrapper][data-appearance='pool-button-outline'] {
    @include m.pool-button('pool-button-outline');
    background-color: transparent;
    border: 2px solid var(--tui-base-13);
    border-radius: var(--tui-radius-xl);
    font: var(--tui-font-text-secondart-xl) !important;
    padding: 4px 20px !important;
    height: auto !important;
    letter-spacing: normal !important;
    color: var(--tui-base-13) !important;
    width: 100%;

    .t-content {
        font: var(--tui-font-text-m) !important;
        font-weight: normal !important;
    }
}

[tuiWrapper][data-appearance='pool-button-verimy'] {
    @include m.pool-button('pool-button-verimy');
    border-radius: 3px;
    text-transform: lowercase;
    font-size: 1rem;
}

