@use 'styles/utils/unit-converter' as f;

.f-c {
    &-base-11 {
        color: var(--tui-base-11);
    }
}

.pool-title {
    color: var(--tui-base-02);
    font: var(--tui-font-heading-6);
    letter-spacing: unset;
    text-transform: lowercase;

    &__subtitle {
        font: var(--tui-font-text-s);
        text-transform: lowercase;
    }
}

.pool-label {
    color: var(--tui-base-13);
    font: var(--tui-font-heading-7);
    letter-spacing: var(--tui-letter-spacing-s);
    text-transform: uppercase;
    line-height: f.unit-converter(20px, rem);

    .t-label {
        margin-bottom: 10px !important;
        font: var(--tui-font-heading-7) !important;
        font-weight: bold !important;
    }
}

.pool-success {
    position: relative;
    color: var(--tui-success-fill);
}

tui-hint {
    background: var(--tui-secondary) !important;
}

.pool-col-width {
    max-width: inherit;

    @media screen and (min-width: 768px) {
        max-width: f.unit-converter(314px, rem) !important;
        min-width: f.unit-converter(314px, rem) !important;
        box-sizing: content-box;
    }
}
