// REQUIRED
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// OPTIONAL
@import 'bootstrap/scss/bootstrap-utilities';
@import 'bootstrap/scss/bootstrap-grid';

.container-fluid {
    width: unset;
}
