@use 'styles/mixins/keyframes';

.t-loader {
    color: var(--tui-base-11) !important;
}

.pool-logo-preloader {
    opacity: 1;
    -webkit-animation: logo-fade 5s infinite;
    -moz-animation: logo-fade 5s infinite;
    -o-animation: logo-fade 5s infinite;
    animation: logo-fade 5s infinite;
}

.pool-preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    opacity: 0;
    pointer-events: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    transition: opacity 500ms;
}

app-root:empty + .pool-preloader {
    z-index: 99999;
    opacity: 1;
}

@include keyframes.keyframes(logo-fade) {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
