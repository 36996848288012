@use "styles/utils/unit-converter" as f;

.pool-label {
    color: var(--tui-base-13);
    font: var(--tui-font-heading-7);
    letter-spacing: var(--tui-letter-spacing-xs);
    text-transform: lowercase;
    line-height: f.unit-converter(20px, rem);

    .t-label {
        margin-bottom: 10px !important;
        font: var(--tui-font-heading-7) !important;
        font-weight: bold !important;
    }
}
