@use 'styles/utils/unit-converter' as f;

.pool-tabs {
    --tui-primary: #651FFF;
    justify-content: center;
    height: f.unit-converter(36px, rem) !important;
    font: var(--tui-font-heading-9);
    font-weight: bold;

    [tuiTab] {
        font-weight: bold;
        text-transform: lowercase;
        letter-spacing: var(--tui-letter-spacing-xs);
        padding: 0 f.unit-converter(45px, em);
        color: var(--tui-base-13);
        background-color: var(--tui-primary);
        border-radius: var(--tui-radius-xl);

        @media screen and (max-width: 47.9625em) {
            padding: 0 f.unit-converter(25px, em);
        }

        &:not(:focus-visible):not(:hover):not(._active) {
            color: var(--tui-base-06);
            background-color: unset;
        }
    }
}
