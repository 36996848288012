@import 'bootstrap';
@import 'theme/variables';
@import 'theme/utils';
@import 'layout/textfield';
@import 'layout/button';
@import 'layout/label';
@import 'layout/checkbox';
@import 'layout/tabs';
@import 'layout/dropdown';
@import 'layout/stepper';
@import 'layout/loader';
@import '@taiga-ui/core/styles/variables/media.scss';

* {
    font-family: var(--tui-text-font);
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    background: var(--tui-primary-background);
    font-size: 1rem;
}

html {
    @media screen and (max-width: 47.9625em) {
        font-size: 0.9rem;
    }
}

body {
    margin: 0;
}

input:-webkit-autofill, {
    transition: background-color 5000s ease-in-out 0s !important;
}

tui-root {
    height: 100%;
}
