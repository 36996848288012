@font-face {
    font-family: 'Mulish';
    src: url('../../fonts/mulish/bold/Mulish-Bold.woff2') format('woff2'),
        url('../../fonts/mulish/bold/Mulish-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../../fonts/mulish/regular/Mulish-Regular.woff2') format('woff2'),
        url('../../fonts/mulish/regular/Mulish-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../../fonts/mulish/black/Mulish-Black.woff2') format('woff2'),
        url('../../fonts/mulish/black/Mulish-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../../fonts/mulish/semibold/Mulish-SemiBold.woff2') format('woff2'),
        url('../../fonts/mulish/semibold/Mulish-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins/bold/Poppins-Bold.woff2') format('woff2'),
        url('../../fonts/poppins/bold/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

$font-family: 'Archia';

@font-face {
    font-family: 'Archia';
    font-weight: light;
    src: url('../../fonts/archia/Archia-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Archia';
    font-weight: normal;
    src: url('../../fonts/archia/Archia-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Archia';
    font-weight: bold;
    src: url('../../fonts/archia/Archia-Bold.otf') format('opentype');
}
