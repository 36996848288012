@use "sass:map";
@import '@taiga-ui/core/styles/mixins/wrapper.scss';

$color-map: (
    'pool-button': (
        'default': var(--tui-primary),
        'hover': var(--tui-primary-hover),
        'active': var(--tui-primary-active),
        'font-color': var(--tui-base-10),
    ),
    'pool-button-outline': (
        'default': transparent,
        'hover': var(--tui-base-10),
        'active': var(--tui-primary-active),
        'font-color': var(--tui-primary),
    ),
    'pool-button-verimy': (
        'default': var(--tui-positive-2),
        'hover': var(--tui-positive-hover),
        'active': var(--tui-positive),
        'font-color': var(--tui-base-02),
    ),
);

@mixin pool-button($appearance: 'pool-button') {
    border-radius: var(--tui-radius-xxl);
    font: var(--tui-font-heading-6);
    letter-spacing: unset;
    background: var(--tui-login-background);
    color: var(--tui-base-02);
}
