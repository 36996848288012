@use 'styles/utils/unit-converter' as f;

.pool-stepper {
    $gap: f.unit-converter(60px, rem);

    margin-top: $gap;
    display: flex;
    flex-direction: column;
    gap: 4.8rem;

    &__step {
        display: flex;
        gap: var(--tui-padding-m);
        align-items: center;
        position: relative;
        height: 10px;

        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 3.65rem;
            background: var(--tui-base-02);
            border-radius: 1px;
            position: absolute;
            left: 10px;
            top: 1.24rem;
        }

        &:last-child::after {
            display: none;
        }
    }

    &__indicator {
        width: f.unit-converter(22px, rem);
        height: f.unit-converter(22px, rem);
        background-color: var(--tui-login-background);
        border-radius: 50%;
        position: relative;

        &--active {
            &::after {
                content: '';
                position: absolute;
                width: f.unit-converter(6px, rem);
                height: f.unit-converter(13px, rem);
                border-bottom: solid 2px var(--tui-base-02);
                border-right: solid 2px var(--tui-base-02);
                transform: translate(7px, 2px) rotate(45deg);
            }
        }

        &--disabled {
            pointer-events: none;
            cursor: default;
        }
    }

    &__text {
        padding: 4px;
        font-weight: 700;
        color: var(--tui-base-02);
        text-transform: lowercase;
        letter-spacing: var(--tui-letter-spacing-s);
        cursor: pointer;
        transition: color var(--tui-duration);
        position: absolute;
        left: 40px;

        &:hover {
            color: var(--tui-base-10);
        }
    }

    &__next {
        margin-top: 8rem !important;
    }
}
