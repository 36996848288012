tui-primitive-checkbox {
    [tuiWrapper][data-appearance='outline'] {
        background: #26282B;
        box-shadow: 1px 1px 2px rgba(56, 59, 63, 0.3), -1px -1px 2px rgba(20, 21, 23, 0.5), inset -1px 1px 2px rgba(20, 21, 23, 0.2), inset 1px -1px 2px rgba(20, 21, 23, 0.2), inset -1px -1px 2px rgba(56, 59, 63, 0.9), inset 1px 1px 3px rgba(20, 21, 23, 0.9);
    }

    [tuiWrapper][data-appearance='primary'] {
        background: var(--tui-primary-active);
    }
}
