@import 'src/assets/scss/modules/_fonts.scss';

:root {
    --tui-heading-font: 'Archia';
    --tui-text-font: 'Archia';
    --tui-text-font-secondary: 'Poppins';

    // Fonts
    --tui-font-heading: var(--tui-heading-font);
    --tui-font-heading-1: bold 3.125rem/3.5rem var(--tui-font-heading);
    --tui-font-heading-2: bold 2.75rem/3rem var(--tui-font-heading);
    --tui-font-heading-3: bold 2.25rem/2.5rem var(--tui-font-heading);
    --tui-font-heading-4: bold 1.75rem/2rem var(--tui-font-heading);
    --tui-font-heading-5: bold 1.5rem/1.75rem var(--tui-font-heading);
    --tui-font-heading-6: bold 1.25rem/1.5rem var(--tui-font-heading);
    --tui-font-heading-7: bold 0.75rem/0.875 var(--tui-font-heading);
    --tui-font-heading-8: bold 0.75rem/1 var(--tui-font-heading);
    --tui-font-heading-9: bold 0.938rem/1rem var(--tui-font-heading);

    --tui-font-text: var(--tui-text-font);
    --tui-font-text-xl: normal 1.1875rem/1.75rem var(--tui-font-text);
    --tui-font-text-l: normal 1.0625rem/1.5rem var(--tui-font-text);
    --tui-font-text-l-2: normal 1.0625rem/1.25rem var(--tui-font-text);
    --tui-font-text-m: normal 1rem/1.5rem var(--tui-font-text);
    --tui-font-text-m-2: normal 1rem/1.25rem var(--tui-font-text);
    --tui-font-text-s: normal 0.875rem/1.25rem var(--tui-font-text);
    --tui-font-text-s-2: normal 0.8125rem/1rem var(--tui-font-text);
    --tui-font-text-xs: normal 0.6875rem/1rem var(--tui-font-text);
    --tui-font-text-xxs: normal 0.4875rem/1rem var(--tui-font-text);

    --tui-font-text-secondart-xl: bold 1.25rem/1.25rem var(--tui-font-text);

    //letter-spacing
    --tui-letter-spacing-xs: 0.125rem;
    --tui-letter-spacing-s: 0.188rem;

    // Sizes
    --tui-radius-xs: 0.25rem;
    --tui-radius-s: 0.5rem;
    --tui-radius-m: 0.75rem;
    --tui-radius-l: 1rem;
    --tui-radius-xl: 1.5rem;
    --tui-radius-xxl: 2rem;
    --tui-radius-xxxl: 3rem;
    --tui-height-xs: 2rem;
    --tui-height-s: 2.5rem;
    --tui-height-m: 2.75rem;
    --tui-height-l: 3.5rem;

    // Input padding
    --tui-padding-s: 0.75rem;
    --tui-padding-m: 0.75rem;
    --tui-padding-l: 1rem;

    --tui-margin-l: 1.5rem;
    --tui-margin-xxxl: 4.5rem;

    // Misc
    --tui-duration: 0.3s;
    --tui-backdrop: none;

    // Special colors
    --tui-disabled-opacity: 0.56;
    --tui-autofill: #292a2e;

    // Base colors
    --tui-base-01: #18181b;
    --tui-base-02: #f6f6f6; // secondary background, marker icons background
    --tui-base-03: transparent; // basic border
    --tui-base-04: transparent; // hovered borders
    --tui-base-05: #b0b0b0; // interface icons
    --tui-base-06: #959595; // hovered interface icons
    --tui-base-07: #red; // inverted background
    --tui-base-08: #333; // dark interface icons
    --tui-base-09: #fff; // icons on inverted background
    --tui-base-10: #015A7B; // icons on inverted background
    --tui-base-11: #5ACEFF;
    --tui-base-12: #000000;
    --tui-base-13: #fff;

    --tui-primary: #DAF5FF; // primary buttons, background
    --tui-primary-hover: #BFE7F6FF; // primary buttons hover
    --tui-primary-active: #8ECFE8FF; // primary buttons pressed
    --tui-primary-text: #fff; // text on primary background
    --tui-login-background: #651FFF;
    --tui-primary-background: #000; // text on primary background
    --tui-secondary-background: #424242;

    --tui-secondary: #212121; // inputs and secondary buttons
    --tui-secondary-hover: #292a2e; // inputs and secondary buttons hover
    --tui-secondary-active: #292a2e; // inputs and secondary buttons pressed

    --tui-accent: #ff7043; // fill and outline of accent elements
    --tui-accent-hover: #ff9a94; // accent elements hover
    --tui-accent-active: #e7716a; // accent elements pressed
    --tui-accent-text: #fff; // text on accent background

    --tui-selection: rgba(112, 182, 246, 0.12); // selected text background
    --tui-focus: rgba(51, 51, 51, 0.64); // focus ring color
    --tui-clear: rgba(0, 0, 0, 0.04); // translucent dark fill
    --tui-clear-disabled: rgba(0, 0, 0, 0.04); // translucent dark fill disabled
    --tui-clear-hover: rgba(0, 0, 0, 0.16); // translucent dark fill hover
    --tui-clear-active: rgba(0, 0, 0, 0.2); // translucent dark fill pressed
    --tui-clear-inverse: rgba(255, 255, 255, 0.16); // translucent light fill
    --tui-clear-inverse-hover: rgba(255, 255, 255, 0.24); // translucent light fill hover
    --tui-clear-inverse-active: rgba(255, 255, 255, 0.4); // translucent light fill pressed
    --tui-elevation-01: #fff; // background of elevated elements
    --tui-elevation-02: #fff; // background of elevated elements

    // Statuses
    --tui-error-fill: rgba(244, 87, 37, 1); // icons and decorative elements with error status
    --tui-error-bg: #4F1C0E; // translucent error background
    --tui-error-bg-hover: rgba(244, 87, 37, 0.24); // translucent hover error background
    --tui-success-fill: rgba(74, 201, 155, 1); // icon and decorative elements with success status
    --tui-success-bg: rgba(74, 201, 155, 0.12); // translucent success background
    --tui-success-bg-hover: rgba(74, 201, 155, 0.24); // translucent success hover
    --tui-warning-fill: rgba(255, 199, 0, 1); // icon and decorative elements with warning status
    --tui-warning-bg: rgba(255, 199, 0, 0.12); // translucent warning background
    --tui-warning-bg-hover: rgba(255, 199, 0, 0.24); // translucent warning background
    --tui-info-fill: rgba(112, 182, 246, 1); // icon and decorative elements with info status
    --tui-info-bg: rgba(112, 182, 246, 0.12); // translucent info background
    --tui-info-bg-hover: rgba(112, 182, 246, 0.24); // translucent info background
    --tui-neutral-fill: rgb(121, 129, 140); // icon and decorative elements with neutral status
    --tui-neutral-bg: rgba(121, 129, 140, 0.12); // translucent info background
    --tui-neutral-bg-hover: rgba(121, 129, 140, 0.24); // translucent info background

    // Text
    --tui-text-01: #fff;
    --tui-text-02: rgba(255, 255, 255, 0.72);
    --tui-text-03: rgba(27, 31, 59, 0.4);
    --tui-text-04: #333;
    --tui-link: #651FFF;
    --tui-link-hover: #6c86e2;
    --tui-positive: #00E676;
    --tui-positive-2: #57BF9F;
    --tui-positive-hover: #7ac5aa;
    --tui-negative: #dd4c1e;
    --tui-negative-hover: #e38163;

    // Modifiers for dark background
    --tui-error-fill-night: rgba(255, 140, 103, 1);
    --tui-error-bg-night: rgba(244, 87, 37, 0.32);
    --tui-error-bg-night-hover: rgba(244, 87, 37, 0.4);
    --tui-success-fill-night: rgb(74, 201, 155);
    --tui-success-bg-night: rgba(74, 201, 155, 0.32);
    --tui-success-bg-night-hover: rgba(74, 201, 155, 0.4);
    --tui-warning-fill-night: rgb(255, 199, 0);
    --tui-warning-bg-night: rgba(255, 199, 0, 0.32);
    --tui-warning-bg-night-hover: rgba(255, 199, 0, 0.4);
    --tui-info-fill-night: rgb(112, 182, 246);
    --tui-info-bg-night: rgba(112, 182, 246, 0.32);
    --tui-info-bg-night-hover: rgba(112, 182, 246, 0.4);
    --tui-neutral-fill-night: rgb(149, 155, 164);
    --tui-neutral-bg-night: rgba(149, 155, 164, 0.32);
    --tui-neutral-bg-night-hover: rgba(149, 155, 164, 0.48);
    --tui-autofill-night: rgb(85, 74, 42);
    //
    --tui-text-01-night: rgba(255, 255, 255, 1);
    --tui-text-02-night: rgba(255, 255, 255, 0.72);
    --tui-text-03-night: rgba(255, 255, 255, 0.6);
    --tui-link-night: #6788ff;
    --tui-link-night-hover: #526ed3;
    --tui-positive-night: #44c596;
    --tui-positive-night-hover: #3aa981;
    --tui-negative-night: #ff8c67;
    --tui-negative-night-hover: #bb593a;

}
